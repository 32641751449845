import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import SentMail from '../common/SentMail';
const ContactForm = (props) => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company_name: '',
        message: '',
    });

    const [locDetails, setLocDetails] = useState(null);

    const apiKey = process.env.REACT_APP_OPENCAGE_API_KEY;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    useEffect(() => {
        const headers = {'Content-type': 'application/json', 'Authorization': `Bearer ${process.env.REACT_APP_PRODUCTION_BACKEND_API_TOKEN}` };
        try {
          fetch(`${process.env.REACT_APP_PRODUCTION_BACKEND_API_URL}/api/getIp`, { headers })
              .then(response => response.json())
              .then(data => setLocDetails(data));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    }, []);

    const HandleSubmit = async (e) => {
        e.preventDefault();

        if(formData.name === '' || formData.email === '' || formData.phone === '' || formData.company_name === '' || formData.message === ''){
            swal({
                text: "Please fill all required fields.",
                icon: "info",
            }).then( ok => {
            })

            return false;
        }
        
            
        if(locDetails?.locDetails?.loc){
            var latLong = locDetails?.locDetails?.loc.split(',');
        }

        const data = JSON.stringify({
            "data": {
                "name": formData.name,
                "email": formData.email,
                "phone": formData.phone,
                "company_name": formData.company_name,
                "message": formData.message,
                "ip_address": locDetails.ip,
                "lat": latLong[0] != "" ? latLong[0] : "",
                "long": latLong[1] != "" ? latLong[1] : "",
                "country": locDetails?.locDetails?.country ? locDetails?.locDetails?.country : "",
                "state": locDetails?.locDetails?.region ? locDetails?.locDetails?.region : "",
                "city": locDetails?.locDetails?.city ? locDetails?.locDetails?.city : "",
            }
        })

        // console.log(data);
        // console.log(locDetails);
        // console.log(latLong);

        const status = SentMail(data,process.env.REACT_APP_CONTACT_FORM_API,e);

        if(status){
            setFormData({
                name: '',
                email: '',
                phone: '',
                company_name: '',
                message: '',
            });
        }


    };

  return (
    <>
    <form onSubmit={HandleSubmit} className={`${props.class ? props.class : ''}`}>
        <div className="row g-3">
            <div className="col-md-6">
                <div className="form-floating">
                    <input type="text" className="form-control" value={formData.name} id="name" name="name" placeholder="Name" onChange={handleChange} required />
                    <label htmlFor="name">Name</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-floating">
                    <input type="email" className="form-control" value={formData.email} id="email" name="email" placeholder="Email" onChange={handleChange} required />
                    <label htmlFor="email">Email</label>
                </div>
            </div>
            <div className="col-6">
                <div className="form-floating">
                    <input type="tel" className="form-control" value={formData.phone} id="phone" name="phone" placeholder="Phone" onChange={handleChange} required />
                    <label htmlFor="phone">Phone</label>
                </div>
            </div>
            <div className="col-6">
                <div className="form-floating">
                    <input type="text" className="form-control" value={formData.company_name} name="company_name" id="company" placeholder="company" onChange={handleChange} required />
                    <label htmlFor="company">Company</label>
                </div>
            </div>
            <div className="col-12">
                <div className="form-floating">
                    <textarea className="form-control" value={formData.message} name="message" placeholder="Leave a message here" onChange={handleChange} id="message" style={{height: "150px"}}></textarea>
                    <label htmlFor="message">Message</label>
                </div>
            </div>
            <div className="col-12 mb-4">
                <div className="form-floating">
                    <input type='checkbox' name='consent' id='consent' />
                    <label htmlFor="consent" className={`p-0 ps-4 ${props.button ? 'text-white' : ''}`}>By submitting the form, you will be eligible for receiving the newsletter, and product & services update from Fonimo.</label>
                </div>
            </div>
            <div className="col-12 text-center">
                <button className={`btn btn-${props.button ? props.button : 'primary'}-gradient rounded-pill py-3 px-5`} type="submit">Submit</button>
            </div>
        </div>
    </form>
    </>
  )
}

export default ContactForm