import React from 'react';
import {
  createBrowserRouter
} from "react-router-dom";
import App from '../App';
import PageNotFound from '../components/common/PageNotFound';
import PrivacyPolicy from '../templates/PrivacyPolicy';
import WhiteLabelSoftphone from '../templates/WhiteLabelSoftphone';
import Contact from '../templates/Contact';
import Roadmap from '../templates/Roadmap';
import CustomSoftphone from '../templates/CustomSoftphone';
import Blog from '../templates/Blog';
import Single from '../templates/Single';
// import Category from '../templates/Category';
import Feedback from '../templates/Feedback';
import Partners from '../templates/Partners';
import Qrcode from '../templates/QRcode';
// import FusionPbx from '../templates/FusionPbx';
// import Alternative from '../templates/Alternative';
import Pricing from '../templates/Pricing';
import Expo from '../templates/Expo';
// import Alternatives from '../templates/Alternatives';

const router = createBrowserRouter([
    {
      path: "/",
      element: <App/>,
    },
    {
      path: "/privacy-policy",
      element:  <PrivacyPolicy/>,
    },
    {
      path: "/white-label-softphone",
      element: <WhiteLabelSoftphone/>,
    },
    {
      path: "/custom-voip-softphone",
      element: <CustomSoftphone/>,
    },
    {
      path: "/feedback",
      element: <Feedback/>,
    },
    {
      path: "/partners",
      element: <Partners/>,
    },
    {
      path: "/roadmap",
      element: <Roadmap/>,
    },
    {
      path: "/blog",
      element:  <Blog/>,
    },
    {
      path: "/blog/:slug",
      element:  <Single/>,
    },
    {
      path: "/contact-us",
      element: <Contact/>,
    },
    {
      path: "/qrcode",
      element: <Qrcode/>,
    },
    // {
    //   path: "/fusion-pbx",
    //   element: <FusionPbx/>,
    // },
    {
      path: "/pricing",
      element: <Pricing/>,
    },
    {
      path: "/ucx-expo",
      element: <Expo/>,
    },
    // {
    //   path: "/category/:slug",
    //   element:  <Category/>,
    // },
    // {
    //   path: "/alternative",
    //   element:  <Alternatives/>,
    // },
    // {
    //   path: "/alternative/:slug",
    //   element:  <Alternative/>,
    // },
    {
        path: "*",
        element: <PageNotFound/>,
    },
  ]);

export {router};